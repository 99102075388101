const PanelContainer = {
  baseStyle: {
    minHeight: "calc(100vh - 123px)",
    minW: "1000px"
  },
};

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
};
