import React from 'react'
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Icon,
  Link,
  Switch,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
// Assets
import signInImage from 'assets/img/signInImage.png'
import { FaApple, FaFacebook, FaGoogle } from 'react-icons/fa'
import { STORAGE } from 'constants/common'
import { useHistory } from 'react-router-dom'
import { setCookie } from 'utils'
import { t } from 'i18next'

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('gray.700', 'white')
  const bgForm = useColorModeValue('white', 'navy.800')
  const titleColor = useColorModeValue('gray.700', 'blue.500')
  const colorIcons = useColorModeValue('gray.700', 'white')
  const bgIcons = useColorModeValue('trasnparent', 'navy.700')
  const bgIconsHover = useColorModeValue('gray.50', 'whiteAlpha.100')

  const history = useHistory()

  return (
    <Flex position='relative'>
      <Flex
        h={'100vh'}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        pt={{ md: '0px' }}
      >
        <Flex
          w='100%'
          alignItems='center'
          justifyContent='center'
          mt={{ base: '50px', md: '20px' }}
        >
          <Flex
            zIndex='2'
            direction='column'
            w='445px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: '100px' }}
            m={{ base: '20px', md: 'auto' }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              '0px 5px 14px rgba(0, 0, 0, 0.05)',
              'unset'
            )}
          >
            <Text
              fontSize='20px'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb={'20px'}
            >
              {t('pages.login.title')}
            </Text>
            <FormControl>
              <Button
                fontSize='14px'
                variant='dark'
                fontWeight='bold'
                w='100%'
                h='45'
                //Fake login
                onClick={ () => {
                  window.location.href = `${process.env.REACT_APP_API_URL}/api/saml2/elearning/login`
                }}
              >
                {t('pages.login.login_button')}
              </Button>
            </FormControl>
          </Flex>
        </Flex>
        <Box
          overflowx='hidden'
          h='100%'
          w='100%'
          left='0px'
          position='absolute'
        >
          <Box
            w='100%'
            h='100%'
            bgSize='cover'
            bg='#259f94'
            opacity='0.8'
          ></Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default SignIn
