import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox
} from '@chakra-ui/react'
import React from 'react'
import { Controller } from 'react-hook-form'

const ECheckbox = ({ label, name, control, checkboxLabel = '' , onChange}) => {
    const onChangeCustom = (e, callback) => {
        callback(e)
        if (onChange) {
            onChange(e)
        }
    }
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl isInvalid={!!error}>
          {label && <FormLabel>{label}</FormLabel>}
          <Checkbox {...field} fontSize={'14px'} onChange={(e) => onChangeCustom(e, field.onChange)} isChecked={field.value}>{checkboxLabel}</Checkbox>
          <FormErrorMessage fontSize={'14px'}>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  )
}

export default ECheckbox
