import React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { CookiesProvider } from 'react-cookie'
// Custom Chakra theme
import theme from 'theme/theme.js'
import Router from 'routers.js'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'locales/i18n'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

const queryClient = new QueryClient()

ReactDOM.render(
  <ChakraProvider theme={theme} resetCss={false} position='relative'>
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <Router />
        <ReactQueryDevtools initialIsOpen={false} />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          theme="light"
        />

      </QueryClientProvider>
    </CookiesProvider>
  </ChakraProvider>,
  document.getElementById('root')
)
