import { FormControl, FormLabel,FormErrorMessage, Select  } from '@chakra-ui/react'
import React from 'react'
import { Controller } from 'react-hook-form';

const ESelect = ({ label, name, placeholder, control, dataSelect, style }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl isInvalid={!!error}>
          {label && <FormLabel>{label}</FormLabel>}
          <Select {...field} placeholder={placeholder} fontSize={'14px'} sx={style}>
            {dataSelect?.map((item, index) => (
              <option key={index} value={item.id}>{item.name}</option>
            ))}
          </Select>
          <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  )
}

export default ESelect