import React, { useState, useEffect } from "react";
import {
  Box,
  Center,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import * as yup from "yup";
import moment from "moment";
import PrimaryButton from "components/Buttons/PrimaryButton";
import EInput from "components/EInput";
import Pagination from "components/Pagination/Pagination";
import ESelect from "components/ESelect";
import { HSeparator } from "components/Separator/Separator";
import GreenButton from "components/Buttons/GreenButton";
import EDateRangePicker from "../EDateRangePicker";
import {
  WORKING_STATUS,
  JOB,
  ROLE_1_NAME,
  ROLE_2_NAME,
  POSITION,
  EMPLOYMENT_TYPE,
} from "constants";

const ModalEmployees = ({
  isOpen,
  onClose,
  dataEmployees,
  totalCount,
  paramsListEmployee,
  setParamsListEmployee,
  selectedList,
  setSelectedList,
  allCompany,
  exceptionList,
  setExceptionList,
  editMode,
  isLoading,
  setValue
}) => {
  const schema = yup.object().shape({
    from: yup.string().nullable(),
    to: yup
      .string()
      .nullable()
      .test("error", t("messages.date_end"), (value, context) => {
        return !(value && new Date(value) < new Date(context.parent.from));
      }),
  });

  const methods = useForm({
    defaultValues: {
      name: "",
      from: "",
      to: "",
      work_status: "",
      role_1: "",
      job: "",
      department: "",
      role_2: "",
      position: "",
      employment_type: "",
      business: "",
      code: "",
    },
    resolver: yupResolver(schema),
  });
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [selectedListState, setSelectedListState] = useState();
  const [exceptionListState, setExceptionListState] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50
  })

  const checkSelected = (item) => {
    if (allCompany) {
      return !exceptionListState?.find(
        (exceptionItem) => exceptionItem.id === item.id
      );
    }
    return !!selectedListState?.find((selected) => selected.id === item.id);
  };

  const getPagingParams = (key) => {
    switch (key) {
      case 'showCondition':
        return paramsListEmployee.isPaging ? Math.ceil(totalCount / paramsListEmployee.limit) > 1 : Math.ceil(dataEmployees?.length / pagination.limit) > 1
      case 'total':
        return paramsListEmployee.isPaging ? totalCount : dataEmployees?.length
      case 'limit':
        return paramsListEmployee.isPaging ? paramsListEmployee.limit : pagination.limit
      case 'page':
        return paramsListEmployee.isPaging ? paramsListEmployee.page : pagination.page
      case 'setFunc':
        return paramsListEmployee.isPaging ? setParamsListEmployee : setPagination
    }
  }

  const onSubmit = (data) => {
    const paramsData = {
      name: data.name,
      from: data.from
        ? moment(data.from).format("YYYY-MM-DD")
        : "",
      to: data.to
        ? moment(data.to).format("YYYY-MM-DD")
        : "",
      work_status: data.work_status,
      role_1: data.role_1,
      job: data.job,
      department: data.department,
      role_2: data.role_2,
      position: data.position,
      employment_type: data.employment_type,
      business: data.business,
      code: data.code,
      id_selected_list: null,
      id_exception_list: null,
      no_data: 0,
    };
    if (Object.values(data).find((item) => !!item)) {
      setParamsListEmployee({
        ...paramsListEmployee,
        ...paramsData,
        isPaging: 0,
        page: 1
      });
      setPagination({
        ...pagination,
        page: 1
      })
      return;
    }

    if (allCompany && exceptionListState && exceptionListState.length) {
      setParamsListEmployee({
        ...paramsListEmployee,
        ...paramsData,
        id_exception_list: JSON.stringify(
          exceptionListState.map((item) => item.id)
        ),
        id_selected_list: null,
        no_data: 0,
        isPaging: 1
      });

      return;
    }

    if (selectedListState && selectedListState.length) {
      setParamsListEmployee({
        ...paramsListEmployee,
        ...paramsData,
        id_selected_list: JSON.stringify(
          selectedListState.map((item) => item.id)
        ),
        id_exception_list: null,
        no_data: 0,
        isPaging: 1
      });

      return;
    }

    setParamsListEmployee({
      ...paramsListEmployee,
      ...paramsData,
      id_exception_list: null,
      id_selected_list: null,
      no_data: allCompany ? 0 : 1,
    });
  };

  const handleCheckboxChange = (event, item) => {
    if (allCompany) {
      if (!event.target.checked) {
        setExceptionListState([
          ...exceptionListState,
          { id: item.id, name: item.name },
        ]);
      } else {
        setExceptionListState(
          exceptionListState?.filter((i) => i.id !== item.id)
        );
      }
      return;
    }

    if (event.target.checked) {
      setSelectedListState([
        ...selectedListState,
        { id: item.id, name: item.name },
      ]);
    } else {
      setSelectedListState(selectedListState?.filter((i) => i.id !== item.id));
    }
  };

  const onChangeSelectAll = (isChecked) => {
    if (paramsListEmployee.isPaging && !isChecked) {
      allCompany ? setValue('is_required_all', false) : setSelectedList([])
      return
    }
    if (isChecked) {
      if (allCompany) {
        setExceptionListState(prevState => prevState.filter(item => !dataEmployees.some(employeeItem => item.id === employeeItem.id)))
      }
      setSelectedListState(prevState => prevState
        .concat(dataEmployees
          .filter(employeeItem => !prevState
            .some(prevStateItem => prevStateItem.id === employeeItem.id))
          .map(item => ({ name: item.name, id: item.id }))));
      return;
    }

    if (allCompany) {
      setExceptionListState(prevState => prevState
        .concat(dataEmployees
          .filter(employeeItem => !prevState
            .some(prevStateItem => prevStateItem.id === employeeItem.id))
          .map(item => ({ name: item.name, id: item.id }))));
    }
    setSelectedListState(prevState => prevState.filter(item => !dataEmployees.some(employeeItem => item.id === employeeItem.id)));
  };

  const closeModal = () => {
    methods.reset()
    resetFormValues(['from', 'to'])
    onSubmit({})
    setExceptionListState([])
    setSelectedListState([])
    onClose()
  }

  const resetFormValues = (fields) => {
    fields.forEach(field => {
      methods.setValue(field, null)
    })
  }

  const checkAllCondition = () => {
    if (
      (!Array.isArray(exceptionListState) &&
        !Array.isArray(selectedListState)) ||
      !Array.isArray(dataEmployees) ||
      !dataEmployees.length
    ) {
      return false;
    }
    if (allCompany) {
      return (
        exceptionListState
          .map((item) =>
            dataEmployees.find((employeeItem) => item.id === employeeItem.id)
          )
          .filter((item) => item !== undefined).length === 0
      );
    }
    return (
      dataEmployees
        .map((employeeItem) =>
          selectedListState?.find((item) => item.id === employeeItem.id)
        )
        .filter((item) => item !== undefined).length === dataEmployees.length
    );
  };

  const submitAndClose = () => {
    methods.reset();
    onSubmit({});
    setExceptionList(exceptionListState);
    setSelectedList(selectedListState);
    onClose();
  };

  const getListPaginate = () => {
    if (!paramsListEmployee.isPaging && dataEmployees) {
      const offset = (pagination.page - 1) * pagination.limit;
      return dataEmployees.slice(offset, pagination.limit + offset);
    }

    return dataEmployees
  };
  useEffect(() => {
    setSelectedListState([]);
    setExceptionListState([]);
  }, [allCompany]);

  useEffect(() => {
    setExceptionListState(exceptionList);
    setSelectedListState(selectedList);
  }, [selectedList, exceptionList, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      onSubmit({});
    }
  }, [selectedListState, exceptionListState]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("pages.course_form.list_member")}</ModalHeader>
        <ModalCloseButton />
        <HSeparator />
        <ModalBody>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex mt={"10px"} alignItems={"center"}>
              <Box w={"25%"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.employee_code")}
                </Text>
              </Box>
              <Box w={"60%"}>
                <EInput
                  name="code"
                  placeholder=""
                  control={methods.control}
                  style={{ h: "30px" }}
                />
              </Box>
              <Box w={"25%"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.member_name")}
                </Text>
              </Box>
              <Box w={"60%"}>
                <EInput
                  name="name"
                  placeholder=""
                  control={methods.control}
                  style={{ h: "30px" }}
                />
              </Box>
              <Box w={"25%"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.job_title")}
                </Text>
              </Box>
              <Box w={"60%"}>
                <ESelect
                  name="job"
                  placeholder=""
                  control={methods.control}
                  dataSelect={JOB}
                  style={{ h: "30px" }}
                />
              </Box>
            </Flex>
            <Flex alignItems={"center"} mt={"10px"}>
              <Box w={"25%"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.salary_sys")}
                </Text>
              </Box>
              <Box w={"60%"}>
                <ESelect
                  name="employment_type"
                  placeholder=""
                  control={methods.control}
                  dataSelect={EMPLOYMENT_TYPE}
                  style={{ h: "30px" }}
                />
              </Box>
              <Box w={"25%"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.hiring_date")}
                </Text>
              </Box>
              <Box w={"60%"}>
                <EDateRangePicker
                  style={{ h: "30px" }}
                  disabled={false}
                  dateFormat="yyyy-MM-dd"
                  setValue={methods.setValue}
                  control={methods.control}
                  name="from"
                  getValues={methods.getValues}
                  reset={methods.reset}
                />
              </Box>
              <Box w={"25%"} textAlign={"center"} marginRight={"5px"}>
                <Text>~</Text>
              </Box>
              <Box w={"60%"}>
                <EDateRangePicker
                  style={{ h: "30px" }}
                  disabled={false}
                  dateFormat="yyyy-MM-dd"
                  setValue={methods.setValue}
                  control={methods.control}
                  name="to"
                  getValues={methods.getValues}
                  reset={methods.reset}
                />
              </Box>
            </Flex>
            <Flex alignItems={"center"} mt={"10px"}>
              <Box
                w={"25%"}
                textAlign={"right"}
                marginRight={"5px"}
                fontSize={"14px"}
              >
                <Text>{t("pages.course_form.role_1")}</Text>
              </Box>
              <Box w={"60%"}>
                <ESelect
                  name="role_1"
                  placeholder=""
                  control={methods.control}
                  dataSelect={ROLE_2_NAME}
                  style={{ h: "30px" }}
                />
              </Box>
              <Box w={"25%"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>{t("pages.course_form.role_2")}</Text>
              </Box>
              <Box w={"60%"}>
                <ESelect
                  name="role_2"
                  placeholder=""
                  control={methods.control}
                  dataSelect={ROLE_1_NAME}
                  style={{ h: "30px" }}
                />
              </Box>
              <Box w={"25%"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.affiliation")}
                </Text>
              </Box>
              <Box w={"60%"}>
                <EInput
                  style={{ h: "30px" }}
                  name="department"
                  placeholder=""
                  control={methods.control}
                />
              </Box>
            </Flex>
            <Flex alignItems={"center"} mt={"10px"}>
              <Box w={"25%"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>{t("pages.course_form.system")}</Text>
              </Box>
              <Box w={"60%"}>
                <EInput
                  style={{ h: "30px" }}
                  name="business"
                  placeholder=""
                  control={methods.control}
                />
              </Box>
              <Box w={"25%"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>{t("pages.course_form.position")}</Text>
              </Box>
              <Box w={"60%"}>
                <ESelect
                  style={{ h: "30px" }}
                  name="work_status"
                  placeholder=""
                  control={methods.control}
                  dataSelect={WORKING_STATUS}
                />
              </Box>
              <Box w={"25%"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.grade_name")}
                </Text>
              </Box>
              <Box w={"60%"}>
                <ESelect
                  name="position"
                  placeholder=""
                  control={methods.control}
                  dataSelect={POSITION}
                  style={{ h: "30px" }}
                />
              </Box>
            </Flex>
            <Flex alignItems={"center"} mt={"10px"} justifyContent={"end"}>
              <Box mr={"10px"}>
                <PrimaryButton
                  text={t("pages.course_form.add_member")}
                  onClick={submitAndClose}
                />
              </Box>
              <Box>
                <GreenButton
                  text={t("pages.course_form.search")}
                  type="submit"
                />
              </Box>
            </Flex>
          </form>
          <Table
            variant="simple"
            color={textColor}
            marginTop={"20px"}
            size="xsm"
          >
            <Thead>
              <Tr my=".8rem" pl="0px">
                <Th borderColor={borderColor} width={"1%"} />
                <Th borderColor={borderColor} width={"20%"}>
                  <Text fontSize={"14px"}>
                    {t("pages.course_form.member_name")}
                  </Text>
                </Th>
                <Th borderColor={borderColor} width={"10%"}>
                  <Text fontSize={"14px"}>
                    {t("pages.course_form.hiring_date")}
                  </Text>
                </Th>
                <Th borderColor={borderColor} width={"10%"}>
                  <Text fontSize={"14px"}>
                    {t("pages.course_form.position")}
                  </Text>
                </Th>
                <Th borderColor={borderColor}>
                  <Text fontSize={"14px"}>
                    {t("pages.course_form.job_title")}
                  </Text>
                </Th>
                <Th borderColor={borderColor}>
                  <Text fontSize={"14px"}>
                    {t("pages.course_form.affiliation")}
                  </Text>
                </Th>
                <Th borderColor={borderColor} alignItems={"center"}  width={"17%"}>
                  <Box marginRight={"5px"} display={"flex"}>
                    <Text fontSize={"14px"} pr={"5px"}>
                      {t("pages.course_form.select_all")}
                    </Text>
                    <Checkbox
                      colorScheme="green"
                      isChecked={checkAllCondition()}
                      disabled={isLoading}
                      onChange={(e) => {
                        onChangeSelectAll(e.target.checked);
                      }}
                    />
                  </Box>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {getListPaginate()?.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td width={"1%"}>
                      <Text fontSize={"14px"} pb=".5rem">
                        {index + 1}
                      </Text>
                    </Td>
                    <Td width={"20%"}>
                      <Text fontSize={"14px"} pb=".5rem">
                        {item.name}
                      </Text>
                    </Td>
                    <Td width={"10%"}>
                      <Text fontSize={"14px"} pb=".5rem">
                        {item.join_date}
                      </Text>
                    </Td>
                    <Td width={"10%"}>
                      <Text fontSize={"14px"} pb=".5rem">
                        {item.work_status}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={"14px"} pb=".5rem">
                        {item.job}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={"14px"} pb=".5rem">
                        {item.department}
                      </Text>
                    </Td>
                    <Td width={"17%"} textAlign={'right'}>
                      <Text fontSize={"14px"} pb=".5rem"></Text>
                      <Checkbox
                        mr={'9%'}
                        colorScheme="green"
                        isChecked={checkSelected(item)}
                        onChange={(event) => handleCheckboxChange(event, item)}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {isLoading && (
            <Box textAlign="center" mt="40px">
              <Spinner></Spinner>
            </Box>
          )}
          <Center mt={"20px"}></Center>
        </ModalBody>

        <ModalFooter>
          {getPagingParams('showCondition') && (
            <Pagination
              total={getPagingParams('total')}
              perPage={getPagingParams('limit')}
              currentPage={getPagingParams('page')}
              setParams={getPagingParams('setFunc')}
            />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEmployees;
