import {

    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react'
import SecondaryButton from 'components/Buttons/SecondaryButton'
import DeleteButton from 'components/Buttons/DeleteButton';
import { t } from 'i18next'
import React from 'react'

const ModalDeleteDocument = ({ isOpen, onClose, content, onSubmit,  }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody padding={"10px"} paddingLeft={"20px"}>
                    {content}
                </ModalBody>
                <ModalFooter justifyContent={"center"}>
                    <DeleteButton
                        text={'削除'}
                        marginRight={'15px'}
                        onClick={onSubmit}
                    />
                    <SecondaryButton
                        text={t('common.cancel')}
                        onClick={onClose}
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ModalDeleteDocument
