import { Button } from '@chakra-ui/react'
import React from 'react'

const DeleteButton = ({ text, onClick, type='button', isLoading, size, marginRight='0px'}) => {
  return (
    <Button colorScheme='red' onClick={onClick} type={type} isLoading={isLoading} loadingText={text} size={size ?? 'sm'} fontSize={'14px'} marginRight={marginRight}>
      {text}
    </Button>
  )
}

export default DeleteButton
