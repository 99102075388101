import { Box, Flex, Input, Text } from '@chakra-ui/react'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import React from 'react'
import { useRef } from 'react'
import SecondaryButton from "../Buttons/SecondaryButton";

const UploadFile = ({ name, formMethods, textBtn, accept='*' }) => {
  const inputElement = useRef(null)

  const handleClickUpload = () => {
    inputElement.current && inputElement.current.click()
  }

  const { ref, ...rest } = formMethods.register(name)

  return (
    <Box>
      <Input
        type='file'
        name='file'
        display={'none'}
        {...rest}
        ref={(e) => {
          ref(e)
          inputElement.current = e
        }}
        accept={accept}
      />
      <Flex alignItems={'center'}>
        <SecondaryButton text={textBtn} onClick={handleClickUpload} size={'sm'}/>
        <div style={{ maxWidth:'214px' }}>
            {formMethods.watch(name) && formMethods.watch(name)[0] && formMethods.watch(name)[0].name && (<Text ml={2} noOfLines={1}>{formMethods.watch(name)[0].name}</Text>)}
        </div>
      </Flex>
      {formMethods.formState.errors[name] && (
        <Text color='red' fontSize={'14px'}>{formMethods.formState.errors[name].message}</Text>
      )}
    </Box>
  )
}

export default UploadFile
