// chakra imports
import { Box, ChakraProvider, Portal } from '@chakra-ui/react'
import Footer from 'components/Footer/Footer.js'
// core components
import AuthNavbar from 'components/Navbars/AuthNavbar.js'
import { STORAGE } from 'constants/common'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { dashRoutes as routes } from 'routers'
import { getCookie } from 'utils'

export default function Pages(props) {
  const { ...rest } = props

  const accessToken = getCookie(STORAGE.ACCESS_TOKEN)
  
  // ref for the wrapper div
  const wrapper = React.createRef()
  React.useEffect(() => {
    document.body.style.overflow = 'unset'
    // Specify how to clean up after this effect:
    return function cleanup() {}
  })
  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views)
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  const navRef = React.useRef()
  document.documentElement.dir = 'ltr'
  return (
    <Box ref={navRef} w='100%'>
      <Portal containerRef={navRef}>
      </Portal>
      {accessToken ? <Redirect to='/admin/courses' /> : 
      <Box w='100%'>
        <Box ref={wrapper} w='100%'>
          <Switch>
            {getRoutes(routes)}
            <Redirect from='/auth' to='/auth/login' />
          </Switch>
        </Box>
      </Box>}
    </Box>
  )
}
