import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { post } from 'api/requests'
import { del, get, put } from 'api/requests'
import { toast } from 'react-toastify'
import { t } from 'i18next'

const getListCourse = async (params) => await get('course/list', params)
const detailCourse = (id) => get(`course/detail/${id}`)
const createCourse = (payload) => post('course/create', payload)
const updateCourse = (payload) => post(`course/update/${payload.id}`, payload)
const deleteCourse = (id) => del(`course/delete/${id}`)
const pushJobManual = (payload) => post(`course/manual-push/${payload.id}`, payload)

export const usePushJobManual = (param = {}) => {
  const queryClient = useQueryClient()
  const history = useHistory()

  return useMutation((payload) => {
    payload.employee_id = JSON.parse(localStorage.getItem('auth_user')).id
    return pushJobManual(payload)
  }, {
    onSuccess: (response) => {
      toast.success(t('pages.course_form.push_success'))
      queryClient.invalidateQueries('course/detail')
      history.push(`/admin/courses/detail/${response.result?.data}`)
    },
    onError: () => {
      toast.error('進捗失敗')
    }
  })
}

export const useGetListCourse = (param = {}) => {
  return useQuery(['course/list', param], () => getListCourse(param), {
    enabled: true
  })
}

export const getDetailCourse = (id, enabled) => {
  return useQuery(['getCourse', id], () => detailCourse(id), {
    enabled: !!id && !!enabled
  })
}

export const useCreateCourse = () => {
  const queryClient = useQueryClient()
  const history = useHistory()

  return useMutation((payload) => createCourse(payload), {
    onSuccess: () => {
      toast.success(t('pages.course_form.created_success'))
      queryClient.invalidateQueries('course/list')
      history.push('/admin/courses')
    },
    onError: () => {
      toast.error(t('pages.course_form.failed'))
      console.log(err)
    }
  })
}

export const useUpdateCourse = () => {
  const queryClient = useQueryClient()

  return useMutation((payload) => updateCourse(payload), {
    onSuccess: () => {
      toast.success(t('pages.course_form.updated_success'))
      queryClient.invalidateQueries('course/list')
    },
    onError: () => {
      toast.error(t('pages.course_form.failed'))
    }
  })
}

export const useDeleteCourse = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  return useMutation((id) => deleteCourse(id), {
    onSuccess: () => {
      toast.success(t('pages.course_form.delete_success'))
      queryClient.invalidateQueries('course/list')
      history.push('/admin/courses')
    },
    onError: (err) => {
      toast.error(t('pages.course_form.failed'))
      console.log(err)
    }
  })
}
