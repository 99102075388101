import { Button } from '@chakra-ui/react'
import React from 'react'

const SecondaryButton = ({ text, onClick, type='button', isLoading, size='md'}) => {
  return (
    <Button colorScheme='gray' onClick={onClick} type={type} isLoading={isLoading} loadingText={text} size={'sm'} fontSize={'14px'}>
      {text}
    </Button>
  )
}

export default SecondaryButton
